.hamburger {
  width: 1.5rem;
  height: 1.5rem;
  display: none;
  justify-content: space-around;
  flex-flow: column wrap;
  z-index: 10;
  align-items: center;
}

.burger {
  width: 1.55rem;
  height: 0.25rem;
  border-radius: 10px;
  background-color: black;
  transform-origin: 1px;
  transition: all 0.1s linear;
}

@media (max-width: 767.98px) {
  .hamburger {
  display: flex;
  z-index: 10;
  }
}
