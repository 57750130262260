

.titleSection {
padding-bottom: 5%;
margin-top: 5%;
}

.titleText h1 {
  font-family: 'EB Garamond', serif;
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
}

.homeImage {
  width: 50%;
  border: 1px solid #ddd;
  padding: 1%;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2);
}

.galleryContainer {
  display: flex;
  margin-top: 7%;
  margin-bottom: 7%;
  justify-content: center;
  flex-direction: column;
  align-items: baseline;
}

.galleryRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.galleryRowLast {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.galleryImg {
  width: 20%;
  margin: 2%;
  border: 1px solid #ddd;
  padding: 1%;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2);
}

.galleryImg:hover {
  cursor: pointer;
}

.aboutContainer {
  margin-top: 7%;
  margin-bottom: 7%;
}

.aboutRow {
  display: flex;
  flex-direction: row;
  margin-top: 3%;
  margin-left: 10%;
  margin-right: 10%;
  justify-content: space-between;
  align-items: center;
}

.aboutText {
  width: 40%;
  justify-content: center;
  align-items: center;
}

.aboutImg {
  width: 40%;
  margin: 2%;
  border: 1px solid #ddd;
  padding: 1%;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2);
}

.contactContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 7%;
  margin-bottom: 7%;
}

.instaContainer {
  margin: 5% 7% 0 7%;
}

.emailContainer {
  margin: 7%;
}

.instaBtn {
  margin-top: 5%;
}

.instaLink {
  background-color: #f8a19c;
  color: white;
  padding: 0.7rem 0.7rem;
  text-decoration: none;
  font-family: 'Cantarell', sans-serif;
  border-radius: 0.4rem;
}

.instaIcon {
  font-size: 1.5rem;
  vertical-align: top;
}

.emailLink {
  background-color: #f8a19c;
  color: white;
  padding: 0.7rem 0.7rem;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Cantarell', sans-serif;
  border-radius: 0.4rem;
}

.emailLink:hover {
  cursor: pointer;
}

.finalImg {
  width: 70%;
  border: 1px solid #ddd;
  padding: 1%;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2);
}



/* /////////////////////////////////modal/////////////////////////////// */

.modalActive {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 3%;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.8);
}

/* Modal Content */
.lightbox-img {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0.75%;
  height: 80vh;
  max-width: 90vw;
}

/* The Close Button */
.close {
  color: black;
  position: absolute;
  margin-top: 1%;
  top: 10px;
  right: 25px;
  font-size: 2.5rem;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: black;
  font-weight: bold;
  font-size: 2rem;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev {
  left: 0;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}


/* ///////////////////////////////////modal//////////////////////////////// */



@media (max-width: 767.98px) {
  .titleSection {
    margin-top: 15%;
  }

  .titleText h1 {
    font-size: 1.7rem;
  }

  .homeImage {
    width: 80%;
  }

  .galleryContainer {
    margin-top: 15%;
  }

  .galleryRow {
    flex-direction: column;
  }

  .galleryRowLast {
    flex-direction: column;
    align-items: center;
  }

  .galleryImg {
    width: 80%;
    padding: 3%;
    margin: 5%;
  }

  .modalActive {
    display: none;
  }

  .lightbox-img {
    height: 50vh;
    max-width: 95vw;
  }

  .close {
    margin-top: 20%;
  }

  .contactContainer {
    margin-top: 20%;
  }

  .instaBtn {
    margin-top: 10%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .aboutRow {
    margin-top: 10%;
  }

  .aboutImg {
    width: 45%;
  }

  .aboutText {
    width: 45%;
    text-align: left;
    font-size: 0.7rem;
  }

  .imageContainer {
    margin-top: 7%;
  }

  .finalImg {
    width: 80%;
  }
}
